import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './profiles.css';
import KidsTab from './KidsTab';
import Modal from './Modal';

const ProfilesPage = () => {
    const [activeTab, setActiveTab] = useState('kids');
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [username, setUsername] = useState('');
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [userNameError, setUsernameError] = useState('');
    const [staffName, setStaffName] = useState('');
    const [role, setRole] = useState('');
    const [editUserId, setEditUserId] = useState('');
    const [editName, setEditName] = useState('');
    const [editPin, setEditPin] = useState('');
    const [editConfirmPin, setEditConfirmPin] = useState('');
    const [isChangingPin, setIsChangingPin] = useState(false);
    const [editStaffName, setEditStaffName] = useState('');
    const [editRole, setEditRole] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [isViewing, setIsViewing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [users, setUsers] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isPinEdited, setIsPinEdited] = useState(false); // Track if Pin field has been edited

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-users.php');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleAddUserClick = () => {
        setShowAddUserModal(true);
    };

    const handleCloseModal = () => {
        setShowAddUserModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setUsername('');
        setPin('');
        setConfirmPin('');
        setPinError('');
        setUsernameError('');
        setStaffName('');
        setRole('');
        setIsPinEdited(false); // Reset Pin edited state
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (pin !== confirmPin) {
            setPinError('Pins do not match');
            return;
        }

        setPinError('');
        setUsernameError('');

        try {
            const response = await axios.post('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-users.php', {
                username,
                pin,
                staff_name: staffName,
                role,
            });

            if (response.data.status === 'error' && response.data.message === 'Username already exists') {
                setUsernameError('Username already exists');
            } else {
                handleCloseModal();
                fetchUsers();
                showSuccess('User added successfully');
            }
        } catch (error) {
            console.error('Error adding user:', error);
            setPinError('An error occurred while adding the user');
        }
    };


    const handleEditUser = (user, isView = false) => {
        setEditUserId(user.id);
        setEditName(user.username);
        setEditPin(user.pin);
        setEditStaffName(user.staff_name || '');
        setEditRole(user.role || '');
        setShowEditModal(true);
        setIsViewing(isView);
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
        resetEditFormState();
    };

    const resetEditFormState = () => {
        setEditUserId('');
        setEditName('');
        setEditPin('');
        setEditConfirmPin('');
        setEditStaffName('');
        setEditRole('');
        setConfirmPin('');
        setIsViewing(false);
        setIsPinEdited(false);
        setUsernameError('');
        setPinError('');
        setIsChangingPin(false);
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();

        // Clear any existing errors
        setPinError('');
        setUsernameError('');

        // Only check the pin if the user is changing it
        if (isChangingPin && editPin !== editConfirmPin) {
            setPinError('Pins do not match');
            return;
        }

        try {
            const payload = {
                id: editUserId,
                username: editName,
                staff_name: editStaffName,
                role: editRole,
            };

            if (isChangingPin) {
                payload.pin = editPin;
            }

            const response = await axios.put(`https://attendance.flowerhousecrechecare.com.ng/php/fhcc-users.php?id=${editUserId}`, payload);

            if (response.data.status === 'error' && response.data.message === 'Username already exists') {
                setUsernameError('Username already exists');
            } else {
                setShowEditModal(false);
                resetEditFormState();
                fetchUsers();
                setIsChangingPin(false);
                showSuccess('User updated successfully');
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setPinError('An error occurred while updating the user');
        }
    };



    const handleDeleteUser = (user) => {
        setUserToDelete(user);
        setShowDeleteModal(true);
    };

    const confirmDeleteUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.delete(`https://attendance.flowerhousecrechecare.com.ng/php/fhcc-users.php?id=${userToDelete.id}`);
            setShowDeleteModal(false);
            fetchUsers();
            showSuccess('User deleted successfully');
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };



    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    return (
        <main className="profiles-main">
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="profiles-container">
                {/* Sidebar */}
                <div className="sidebar">
                    <div className="tab-links">
                        <button
                            className={activeTab === 'kids' ? 'active' : ''}
                            onClick={() => handleTabClick('kids')}
                        >
                            Kids
                        </button>
                        <button
                            className={activeTab === 'users' ? 'active' : ''}
                            onClick={() => handleTabClick('users')}
                        >
                            Users
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                <div className="main-content">
                    {/* Conditional rendering based on activeTab */}
                    {activeTab === 'users' && (
                        <>
                            <div className="top-right top-right-user">
                                <button className="profiles-btn add-btn" onClick={handleAddUserClick}>
                                    Add User
                                </button>
                            </div>
                            <div className="users-list">
                                {users.length === 0 ? (
                                    <div className="no-users-found">No users found.</div>
                                ) : (
                                    users.map((user) => (
                                        <div key={user.id} className="user">
                                            <span className='person-name'>{user.username}</span>
                                            <div className='action-buttons'>
                                                <button className="view-btn" onClick={() => handleEditUser(user, true)}>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                                <button className="edit-btn" onClick={() => handleEditUser(user)}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                </button>
                                                <button className="delete-btn" onClick={() => handleDeleteUser(user)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </>
                    )}

                    {activeTab === 'kids' && (
                        <KidsTab />
                    )}

                    {/* Add User Modal */}
                    {showAddUserModal && (
                        <Modal
                            title="Add User"
                            onClose={handleCloseModal}
                            onSubmit={handleFormSubmit}
                            submitButtonText="Add User"
                            showCancel={false}
                            className="modal-content"
                            showSubmitButton={true}
                        >
                            <div className="profiles-form">
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                {userNameError && <p className="error-message">{userNameError}</p>}
                                <div className="form-group">
                                    <label>Pin</label>
                                    <input
                                        type="password"
                                        value={pin}
                                        onChange={(e) => setPin(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Confirm Pin</label>
                                    <input
                                        type="password"
                                        value={confirmPin}
                                        onChange={(e) => setConfirmPin(e.target.value)}
                                        required
                                    />
                                    {pinError && <p className="error-message">{pinError}</p>}
                                </div>
                                <div className="form-group">
                                    <label>Staff Name</label>
                                    <input
                                        type="text"
                                        value={staffName}
                                        onChange={(e) => setStaffName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <input
                                        type="text"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}

                    {/* Edit User Modal */}
                    {showEditModal && (
                        <Modal
                            title={isViewing ? 'View User' : 'Edit User'}
                            onClose={handleEditModalClose}
                            onSubmit={isViewing ? undefined : handleUpdateUser}
                            submitButtonText={isViewing ? '' : 'Update User'}
                            showCancel={false}
                            showSubmitButton={!isViewing}
                            className="modal-content"
                        >
                            <div className="profiles-form">
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={editName}
                                        onChange={(e) => setEditName(e.target.value)}
                                        readOnly={isViewing}
                                        required
                                    />
                                </div>
                                {userNameError && <p className="error-message">{userNameError}</p>}

                                <div className="form-group">
                                    <label>Staff Name</label>
                                    <input
                                        type="text"
                                        placeholder="Staff Name"
                                        value={editStaffName}
                                        onChange={(e) => setEditStaffName(e.target.value)}
                                        readOnly={isViewing}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Role</label>
                                    <input
                                        type="text"
                                        placeholder="Role"
                                        value={editRole}
                                        onChange={(e) => setEditRole(e.target.value)}
                                        required
                                        readOnly={isViewing}
                                    />
                                </div>

                                {isChangingPin && (
                                    <div className="form-group">
                                        <label>New Pin</label>
                                        <input
                                            type="password"
                                            placeholder="New Pin"
                                            value={editPin}
                                            onChange={(e) => {
                                                setEditPin(e.target.value);
                                                setIsPinEdited(true);
                                            }}
                                            required
                                        />
                                        <label>Confirm Pin</label>
                                        <input
                                            type="password"
                                            placeholder="Confirm Pin"
                                            value={editConfirmPin}
                                            onChange={(e) => setEditConfirmPin(e.target.value)}
                                            required
                                        />
                                        {pinError && <p className="error-message">{pinError}</p>}
                                    </div>
                                )}
                                 {!isViewing && (
                                    <div className="form-group">
                                        <button
                                            type="button"
                                            onClick={() => setIsChangingPin(!isChangingPin)}
                                            className="change-pin-button"
                                        >
                                            {isChangingPin ? 'Cancel Pin Change' : 'Change Pin'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Modal>
                    )}


                    {/* Delete User Modal */}
                    {showDeleteModal && (
                        <Modal
                            title="Delete User"
                            onClose={() => setShowDeleteModal(false)}
                            onSubmit={confirmDeleteUser}
                            submitButtonText="Delete"
                            showCancel={true}
                            showSubmitButton={true}
                            className="modal-content delete-modal-content"
                        >
                            <div>
                                <p className='delete-modal-heading'>Are you sure you want to delete this user?</p>
                            </div>
                        </Modal>
                    )}

                </div>
            </div>
        </main>
    );
};

export default ProfilesPage;
