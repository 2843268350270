import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropHelpers';

const CircularImageCrop = ({ src, onComplete }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);


    const handleDone = async () => {
        try {
            const croppedImageUrl = await getCroppedImg(src, croppedAreaPixels);
            onComplete(croppedImageUrl);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div>
            <div style={{ position: 'relative', width: '600px', height: '300px' }}>
                <Cropper
                    image={src}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} 
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    cropShape="round"  
                    showGrid={true}  
                />
            </div>
            <button onClick={handleDone} style={{ marginTop: '10px' }} type="button">Done</button>
        </div>
    );
};

export default CircularImageCrop;
