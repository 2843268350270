import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import placeholderImage from '../img/placeholder-img.jpg';
import CircularImageCrop from './CircularImageCrop';


const KidsTab = () => {
    const [kids, setKids] = useState([]);
    const [error, setError] = useState(null);
    const [showAddKidModal, setShowAddKidModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [surname, setSurname] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [belongsToFamily, setBelongsToFamily] = useState('no');
    const [familyId, setFamilyId] = useState('');
    const [newFamilyName, setNewFamilyName] = useState('');
    const [selectedGuardians, setSelectedGuardians] = useState({
        mother: false,
        father: false,
    });
    const [additionalGuardians, setAdditionalGuardians] = useState([]);
    const [families, setFamilies] = useState([]);
    const [editKidId, setEditKidId] = useState(null);
    const [editSurname, setEditSurname] = useState('');
    const [editFirstName, setEditFirstName] = useState('');
    const [editMiddleName, setEditMiddleName] = useState('');
    const [editGender, setEditGender] = useState('');
    const [editDateOfBirth, setEditDateOfBirth] = useState('');
    const [editBelongsToFamily, setEditBelongsToFamily] = useState('no');
    const [editFamilyId, setEditFamilyId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editNewFamilyName, setEditNewFamilyName] = useState('');
    const [editSelectedGuardians, setEditSelectedGuardians] = useState({
        mother: false,
        father: false,
    });
    const [editAdditionalGuardians, setEditAdditionalGuardians] = useState([]);
    const [isViewing, setIsViewing] = useState(false);
    const [kidToDelete, setKidToDelete] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [croppedPhoto, setCroppedPhoto] = useState(null);
    const [photoSrc, setPhotoSrc] = useState(null);
    const [editPhotoSrc, setEditPhotoSrc] = useState(null);
    const [editPhoto, setEditPhoto] = useState(null);
    const [editCroppedPhoto, setEditCroppedPhoto] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const [currentPhotoPath, setCurrentPhotoPath] = useState(null);

    useEffect(() => {
        fetchKids();
        fetchFamilyOptions();
        setSelectedGuardians([]);
        setAdditionalGuardians([]);
    }, []);


    const fetchKids = async () => {
        setError(null);
        try {
            const response = await axios.get('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-kids.php');
            setKids(response.data);
        } catch (error) {
            setError('Error fetching kids.');
            console.error('Error fetching kids:', error);
        }
    };

    const fetchFamilyOptions = async () => {
        try {
            const response = await axios.get('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-families.php');
            setFamilies(response.data);
        } catch (error) {
            console.error('Error fetching family options:', error);
        }
    };

    const handleAddKidClick = () => {
        setShowAddKidModal(true);
        resetFormState();
        fetchFamilyOptions();
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        setShowAddKidModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setSurname('');
        setFirstName('');
        setMiddleName('');
        setGender('');
        setDateOfBirth('');
        setBelongsToFamily('no');
        setFamilyId('');
        setNewFamilyName('');
        setSelectedGuardians([]);
        setAdditionalGuardians([]);
        setPhoto(null);
        setCroppedPhoto(null);
        setPhotoSrc(null);
    };


    const handleAddGuardian = () => {

        if (isEditing) {
            setEditAdditionalGuardians((prevAdditionalGuardians) => [...prevAdditionalGuardians, '']);
        } else {
            setAdditionalGuardians((prevAdditionalGuardians) => [...prevAdditionalGuardians, '']);
        }
    };



    const handleRemoveGuardian = (index) => {

        if (isEditing) {
            const updatedGuardians = [...editAdditionalGuardians];
            updatedGuardians.splice(index, 1);
            setEditAdditionalGuardians(updatedGuardians);
        } else {
            const updatedGuardians = [...additionalGuardians];
            updatedGuardians.splice(index, 1);
            setAdditionalGuardians(updatedGuardians);
        }
    };

    const handleGuardianCheckboxChange = (guardianId) => {
        if (isEditing) {
            const updatedGuardians = { ...editSelectedGuardians };
            updatedGuardians[guardianId] = !updatedGuardians[guardianId];
            setEditSelectedGuardians(updatedGuardians);
        } else {
            if (selectedGuardians.includes(guardianId)) {
                const updatedGuardians = selectedGuardians.filter(id => id !== guardianId);
                setSelectedGuardians(updatedGuardians);
            } else {
                const updatedGuardians = [...selectedGuardians, guardianId];
                setSelectedGuardians(updatedGuardians);
            }
        }
    };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (selectedGuardians.length === 0 && additionalGuardians.length === 0) {
            setError('Please provide at least one guardian.');
            return;
        }

        // Create a FormData object to handle both text data and the file
        const formData = new FormData();
        formData.append('_method', 'POST'); // Simulate PUT request
        formData.append('surname', surname);
        formData.append('first_name', firstName);
        formData.append('middle_name', middleName);
        formData.append('gender', gender);
        formData.append('date_of_birth', dateOfBirth);
        formData.append('belongs_to_family', belongsToFamily);
        formData.append('family_id', familyId);
        formData.append('new_family_name', newFamilyName);
        formData.append('selected_guardians', JSON.stringify(selectedGuardians));
        formData.append('additional_guardians', JSON.stringify(additionalGuardians));

        // Append the photo file if it's uploaded
        if (photo) {
            formData.append('photo', photo);
        }

        try {
            const response = await axios.post('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-kids.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            handleCloseModal();
            fetchKids();
            fetchFamilyOptions();
            showSuccess('Kid added successfully');
        } catch (error) {
            setError('Error adding kid.');
            console.error('Error adding kid:', error);
        }
    };



    // Function to handle the edit kid logic
    const handleEditKid = (kid, isView = false) => {
        setEditKidId(kid.kid_id);
        setEditSurname(kid.surname);
        setEditFirstName(kid.first_name);
        setEditMiddleName(kid.middle_name || '');
        setEditGender(kid.gender);
        setEditDateOfBirth(kid.date_of_birth);
        setEditBelongsToFamily(kid.belongs_to_family);
        setEditFamilyId(kid.family_id);
        setEditNewFamilyName(kid.family_name || '');

        // Define fixed guardians with consistent casing
        const fixedGuardians = ['mother', 'father'];

        // Convert all guardians to lowercase to ensure consistency
        const lowerCaseGuardians = kid.guardians.map(guardian => guardian.toLowerCase());

        // Separate fixed and additional guardians
        const selectedGuardiansArray = lowerCaseGuardians.filter(guardian => fixedGuardians.includes(guardian));
        const additionalGuardiansArray = kid.guardians.filter(guardian => !fixedGuardians.map(g => g.toUpperCase()).includes(guardian.toUpperCase()));

        // Convert selectedGuardiansArray into the format for editSelectedGuardians
        const selectedGuardiansObject = fixedGuardians.reduce((acc, guardian) => {
            acc[guardian] = selectedGuardiansArray.includes(guardian);
            return acc;
        }, {});

        // Update state
        setEditSelectedGuardians(selectedGuardiansObject);
        setEditAdditionalGuardians(additionalGuardiansArray);

        // Handle the photo for edit form
        if (kid.photo) {
            // Construct the full URL for the photo
            const photoUrl = `https://attendance.flowerhousecrechecare.com.ng/uploads/${kid.photo}`;
            setCurrentPhotoPath(photoUrl); // Track the current photo path
            setEditCroppedPhoto(photoUrl); // For displaying the existing photo
            setEditPhoto(null); // Clear new photo upload state
        } else {
            setCurrentPhotoPath(null);
            setEditCroppedPhoto(null);
            setEditPhoto(null);
        }
        // Update the modal state
        setIsEditing(!isView);
        setShowEditModal(true);
        setIsViewing(isView);
    };

    // Function to handle closing the edit modal
    const handleEditModalClose = () => {
        setShowEditModal(false);
        resetEditFormState();
    };

    // Function to reset the edit form state
    const resetEditFormState = () => {
        setEditKidId('');
        setEditSurname('');
        setEditFirstName('');
        setEditMiddleName('');
        setEditGender('');
        setEditDateOfBirth('');
        setEditBelongsToFamily('no');
        setEditNewFamilyName('');
        setEditSelectedGuardians([]);
        setEditAdditionalGuardians([]);
        setIsViewing(false);
        setEditPhoto(null);
        setEditCroppedPhoto(null);
        setEditPhotoSrc(null);
        setShowCropper(false);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setError(null);
    
        try {
            // Prepare the selected guardians array
            const selectedGuardiansArray = Object.keys(editSelectedGuardians).filter(guardian => editSelectedGuardians[guardian]);
    
            // Create a new FormData object
            const formData = new FormData();
            formData.append('_method', 'PUT'); // Simulate PUT request
            formData.append('kid_id', editKidId);
            formData.append('surname', editSurname);
            formData.append('first_name', editFirstName);
            formData.append('middle_name', editMiddleName);
            formData.append('gender', editGender);
            formData.append('date_of_birth', editDateOfBirth);
            formData.append('belongs_to_family', editBelongsToFamily);
            formData.append('family_id', editFamilyId);
            formData.append('new_family_name', editNewFamilyName);
            formData.append('selected_guardians', JSON.stringify(selectedGuardiansArray));
            formData.append('additional_guardians', JSON.stringify(editAdditionalGuardians));
    
            if (editPhoto) {
                // New photo is uploaded
                formData.append('photo', editPhoto);
            } else if (currentPhotoPath) {
                // No new photo, retain existing photo
                const relativePhotoPath = currentPhotoPath.replace('https://attendance.flowerhousecrechecare.com.ng/uploads/', '');
                formData.append('existing_photo_path', relativePhotoPath);
            } else {
                // Photo removal requested
                formData.append('remove_photo', true);
            }
    
            // Send the data to the backend
            const response = await axios.post('https://attendance.flowerhousecrechecare.com.ng/php/fhcc-kids.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            // Handle the successful response
            setShowEditModal(false);
            resetEditFormState();
            fetchKids();
            showSuccess('Kid updated successfully');
        } catch (error) {
            // Handle errors
            setError('Error updating kid.');
            console.error('Error updating kid:', error);
        }
    };
    

    const handleDeleteKid = (kid) => {
        setKidToDelete(kid);
        setShowDeleteModal(true);
    };

    const confirmDeleteKid = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const response = await axios.delete(`https://attendance.flowerhousecrechecare.com.ng/php/fhcc-kids.php?kid_id=${kidToDelete.kid_id}`);
            handleCloseModal();
            fetchKids();
            showSuccess('Kid deleted successfully');
        } catch (error) {
            setError('Error deleting kid.');
            console.error('Error deleting kid:', error);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string' || string.length === 0) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };


    const handleFamilyIdChange = async (e) => {
        const selectedFamilyId = e.target.value;
        setFamilyId(selectedFamilyId);

        if (selectedFamilyId) {
            try {
                const response = await axios.get(`https://attendance.flowerhousecrechecare.com.ng/php/fhcc-family-guardians.php?family_id=${selectedFamilyId}`);
                if (Array.isArray(response.data)) {
                    const guardianNames = response.data.map(guardian => guardian.toLowerCase());

                    // Define fixed guardians
                    const fixedGuardians = ['mother', 'father'];

                    // Determine which fixed guardians are present
                    const selectedGuardians = fixedGuardians.filter(guardian => guardianNames.includes(guardian));

                    // Determine additional guardians that are not part of the fixed set and capitalize them
                    const additionalGuardians = guardianNames
                        .filter(guardian => !fixedGuardians.includes(guardian))
                        .map(guardian => capitalizeFirstLetter(guardian));

                    // Update the form state
                    setSelectedGuardians(selectedGuardians);
                    setAdditionalGuardians(additionalGuardians);
                } else {
                    console.error('Unexpected response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching guardians:', error);
            }
        } else {
            setSelectedGuardians([]);
            setAdditionalGuardians([]);
        }
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhotoSrc(URL.createObjectURL(file));
            setPhoto(file);
        }
    };

    const handleEditPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setEditPhotoSrc(URL.createObjectURL(file));
            setEditPhoto(file);
            setShowCropper(true);
        }
    };


    const handleCropComplete = async (croppedImageUrl) => {
        try {
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            const uniqueId = Date.now();
            const file = new File([blob], `image_${uniqueId}.jpg`, { type: blob.type });
            setPhoto(file);
            setCroppedPhoto(croppedImageUrl);
            setPhotoSrc(null);
        } catch (error) {
            console.error('Error handling cropped image:', error);
        }
    };

    const handleEditCropComplete = async (croppedImageUrl) => {
        try {
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            const uniqueId = Date.now();
            const file = new File([blob], `image_${uniqueId}.jpg`, { type: blob.type });
            setEditPhoto(file);
            setEditCroppedPhoto(croppedImageUrl);
            setEditPhotoSrc(null);
            setShowCropper(false);
        } catch (error) {
            console.error('Error handling cropped image:', error);
        }
    };

    const handleRemovePhoto = () => {
        setPhoto(null);
        setCroppedPhoto(null);
        setPhotoSrc(null);
    };

    const handleRemoveEditPhoto = () => {
        setEditCroppedPhoto(null);
        setEditPhoto(null);
        setEditPhotoSrc(null);
        setShowCropper(false);
        setCurrentPhotoPath(null)
    };


    const isFamilySelected = familyId !== '';

    return (
        <div className="kids-tab-content">
            {error && <div className="error-message">{error}</div>}
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="top-right top-right-user">
                <button className="profiles-btn add-btn" onClick={handleAddKidClick}>
                    Add Kid
                </button>
            </div>
            <div className="kids-list">
                {kids.length === 0 ? (
                    <div className="no-users-found">No kids found</div>
                ) : (
                    kids.map((kid) => (
                        <div key={kid.kid_id} className="kid">
                            {kid.photo ? (
                                <img
                                    src={`https://attendance.flowerhousecrechecare.com.ng/uploads/${kid.photo}`}
                                    alt="Kid"
                                    className="kid-photo"
                                />
                            ) : (
                                <img
                                    src={placeholderImage}
                                    alt="Placeholder-img"
                                    className="kid-photo"
                                />
                            )}

                            <span className="kid-name">{capitalizeFirstLetter(kid.surname)} {capitalizeFirstLetter(kid.first_name)} </span>
                            <div className="action-buttons">
                                <button className="view-btn" onClick={() => handleEditKid(kid, true)}>
                                    <i className="fas fa-eye"></i>
                                </button>
                                <button className="edit-btn" onClick={() => handleEditKid(kid)}>
                                    <i className="fas fa-pencil-alt"></i>
                                </button>
                                <button className="delete-btn" onClick={() => handleDeleteKid(kid)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Add Kid Modal */}
            {showAddKidModal && (
                <Modal
                    title="Add Kid"
                    onClose={handleCloseModal}
                    onSubmit={handleAddSubmit}
                    submitButtonText="Add Kid"
                    showCancel={false}
                    showSubmitButton={true}
                    className="modal-content"
                >
                    <div className="profiles-form">
                        <div className="form-group">
                            <label>Photo</label>
                            {photoSrc ? (
                                <CircularImageCrop
                                    src={photoSrc}
                                    onComplete={handleCropComplete}
                                />
                            ) : (
                                <>
                                    <img
                                        className='kid-photo'
                                        src={croppedPhoto || placeholderImage}
                                        alt="Kid's Photo"
                                        style={{ width: '110px', height: '100px', borderRadius: '50%', margin: 'auto' }}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handlePhotoChange}
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                    />
                                    <div
                                        className='photo-buttons'
                                    >
                                        <label
                                            htmlFor="fileInput"
                                            className='photo-btn'
                                        >
                                            Choose file
                                        </label>

                                        <label className="photo-btn photo-btn2" onClick={handleRemovePhoto}>
                                            <i className="fas fa-times"></i>
                                        </label>


                                    </div>

                                    <div id="fileName">
                                        {photo ? photo.name : 'No file chosen'}
                                    </div>
                                </>
                            )}
                        </div>


                        <div className="form-group">
                            <label>Surname</label>
                            <input
                                type="text"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Middle Name</label>
                            <input
                                type="text"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Gender</label>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input
                                type="date"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                required
                            />
                        </div>

                        {/* Family Selection */}
                        <div>
                            <label className='radioform-label'>Belongs to Family?</label>
                            <div className='radio-form'>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="yes"
                                            checked={belongsToFamily === 'yes'}
                                            onChange={() => setBelongsToFamily('yes')}
                                        />
                                        Yes
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="no"
                                            checked={belongsToFamily === 'no'}
                                            onChange={() => {
                                                setBelongsToFamily('no');
                                                // Clear guardian lists when selecting 'no'
                                                setSelectedGuardians([]);
                                                setAdditionalGuardians([]);
                                                setFamilyId('');
                                                setNewFamilyName('');
                                            }}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>

                            {belongsToFamily === 'yes' && (
                                <div className="form-group">
                                    <label>Family Name</label>
                                    <div className="select-wrapper">
                                        <select
                                            value={familyId}
                                            onChange={handleFamilyIdChange}
                                        >
                                            <option value="">Select Existing Family</option>
                                            {families.map((family) => (
                                                <option key={family.family_id} value={family.family_id}>
                                                    {family.family_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <input
                                        type="text"
                                        value={newFamilyName}
                                        onChange={(e) => setNewFamilyName(e.target.value)}
                                        placeholder="Or enter new family name"
                                        disabled={familyId !== ''}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Guardians Selection */}
                        <div className="checkbox-form">
                            <label>Authorized Guardians</label>
                            <div>
                                {/* Fixed Guardians Checkboxes */}
                                <div className='checkbox-group'>
                                    <input
                                        type="checkbox"
                                        id="mother"
                                        value="mother"
                                        checked={selectedGuardians.includes('mother')}
                                        onChange={() => handleGuardianCheckboxChange('mother')}
                                        disabled={isFamilySelected || isViewing}
                                    />
                                    <label htmlFor="mother">Mother</label>
                                </div>
                                <div className='checkbox-group'>
                                    <input
                                        type="checkbox"
                                        id="father"
                                        value="father"
                                        checked={selectedGuardians.includes('father')}
                                        onChange={() => handleGuardianCheckboxChange('father')}
                                        disabled={isFamilySelected || isViewing}
                                    />
                                    <label htmlFor="father">Father</label>
                                </div>

                                {/* Additional Guardians */}
                                {additionalGuardians.map((guardian, index) => (
                                    <div key={index} className='guardian-item'>
                                        <input
                                            type="text"
                                            value={guardian}
                                            onChange={(e) => {
                                                const updatedGuardians = [...additionalGuardians];
                                                updatedGuardians[index] = e.target.value;
                                                setAdditionalGuardians(updatedGuardians);
                                            }}
                                            disabled={isFamilySelected || isViewing}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveGuardian(index)}
                                            disabled={isFamilySelected || isViewing}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                type="button"
                                className='addguardian-btn'
                                onClick={handleAddGuardian}
                                disabled={isFamilySelected || isViewing}
                            >
                                Add Guardian
                            </button>
                            {error && <div className="error-message">{error}</div>}
                        </div>

                    </div>
                </Modal>
            )}


            {showEditModal && (
                <Modal
                    title={isViewing ? 'View Kid Details' : 'Edit Kid Details'}
                    onClose={handleEditModalClose}
                    onSubmit={isViewing ? undefined : handleEditSubmit}
                    submitButtonText={isViewing ? '' : 'Update'}
                    showCancel={false}
                    showSubmitButton={!isViewing}
                    className="modal-content"
                >
                    <div className="profiles-form">
                        <div className="form-group">
                            <label>Photo</label>

                            {showCropper ? (
                                <CircularImageCrop
                                    src={editPhotoSrc}
                                    onComplete={handleEditCropComplete}
                                />
                            ) : (
                                <>
                                    <img
                                        className='kid-photo'
                                        src={editCroppedPhoto || placeholderImage}
                                        alt="Kid's Photo"
                                        style={{ width: '110px', height: '100px', borderRadius: '50%', margin: 'auto' }}
                                    />

                                    {!isViewing && (
                                        <>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleEditPhotoChange}
                                                id="editFileInput"
                                                style={{ display: 'none' }}
                                            />
                                            <div className='photo-buttons'>
                                                <label
                                                    htmlFor="editFileInput"
                                                    className='photo-btn'
                                                >
                                                    Choose file
                                                </label>
                                                <label className="photo-btn photo-btn2" onClick={handleRemoveEditPhoto}>
                                                    <i className="fas fa-times"></i>
                                                </label>
                                            </div>

                                            <div id="fileName">
                                                {editPhoto ? editPhoto.name : 'No file chosen'}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>


                        <div className="form-group">
                            <label>Surname</label>
                            <input
                                type="text"
                                value={editSurname}
                                onChange={(e) => setEditSurname(e.target.value)}
                                required
                                readOnly={isViewing}
                            />
                        </div>
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                value={editFirstName}
                                onChange={(e) => setEditFirstName(e.target.value)}
                                required
                                readOnly={isViewing}
                            />
                        </div>
                        <div className="form-group">
                            <label>Middle Name</label>
                            <input
                                type="text"
                                value={editMiddleName}
                                onChange={(e) => setEditMiddleName(e.target.value)}
                                readOnly={isViewing}
                            />
                        </div>
                        <div className="form-group">
                            <label>Gender</label>
                            <select
                                value={editGender}
                                onChange={(e) => setEditGender(e.target.value)}
                                required
                                disabled={isViewing}
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Date of Birth</label>
                            <input
                                type="date"
                                value={editDateOfBirth}
                                onChange={(e) => setEditDateOfBirth(e.target.value)}
                                required
                                readOnly={isViewing}
                            />
                        </div>
                        <div>
                            <label className='radioform-label'>Belongs to Family?</label>
                            <div className='radio-form'>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="yes"
                                            checked={editBelongsToFamily === 'yes'}
                                            onChange={() => setEditBelongsToFamily('yes')}
                                            disabled={isViewing}
                                        />
                                        Yes
                                    </label>
                                </div>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="no"
                                            checked={editBelongsToFamily === 'no'}
                                            onChange={() => setEditBelongsToFamily('no')}
                                            disabled={isViewing}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>

                            {editBelongsToFamily === 'yes' && (
                                <div className="form-group">
                                    <label>Family Name</label>
                                    <div className="select-wrapper">
                                        <select
                                            value={familyId}
                                            onChange={handleFamilyIdChange}
                                            disabled={isViewing}
                                        >
                                            <option value="">Select Existing Family</option>
                                            {families.map((family) => (
                                                <option key={family.family_id} value={family.family_id}>
                                                    {family.family_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <input
                                        type="text"
                                        value={editNewFamilyName}
                                        onChange={(e) => setEditNewFamilyName(e.target.value)}
                                        placeholder="Or enter new family name"
                                        disabled={familyId !== ''}
                                        readOnly={isViewing}
                                    />
                                </div>
                            )}
                        </div>

                        {/* Guardians Selection */}
                        <div className="checkbox-form">
                            <label>Authorized Guardians</label>
                            <div>
                                <div className='checkbox-group'>
                                    <input
                                        type="checkbox"
                                        id="mother"
                                        value="mother"
                                        checked={editSelectedGuardians.mother}
                                        onChange={() => handleGuardianCheckboxChange('mother')}
                                        disabled={isViewing}
                                    />
                                    <label htmlFor="mother">Mother</label>
                                </div>
                                <div className='checkbox-group'>
                                    <input
                                        type="checkbox"
                                        id="father"
                                        value="father"
                                        checked={editSelectedGuardians.father}
                                        onChange={() => handleGuardianCheckboxChange('father')}
                                        disabled={isViewing}
                                    />
                                    <label htmlFor="father">Father</label>
                                </div>

                                {editAdditionalGuardians.map((guardian, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={guardian}
                                            onChange={(e) => {
                                                const updatedGuardians = [...editAdditionalGuardians];
                                                updatedGuardians[index] = e.target.value;
                                                setEditAdditionalGuardians(updatedGuardians);
                                            }}
                                            disabled={isFamilySelected || isViewing}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveGuardian(index)}
                                            disabled={isFamilySelected || isViewing}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                type="button"
                                className='addguardian-btn'
                                onClick={handleAddGuardian}
                                disabled={isViewing}
                            >
                                Add Guardian
                            </button>
                            {error && <div className="error-message">{error}</div>}
                        </div>
                    </div>
                </Modal>
            )}


            {/* Delete Kid Modal */}
            {showDeleteModal && (
                <Modal
                    title="Delete Kid"
                    onClose={handleCloseModal}
                    onSubmit={confirmDeleteKid}
                    submitButtonText="Delete Kid"
                    showCancel={true}
                    showSubmitButton={true}
                    className="delete-modal-content"
                >
                    <div className="delete-modal-heading">
                        <p>Are you sure you want to delete this kid?</p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default KidsTab;
