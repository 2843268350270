import React from 'react';
// import './home-style.css';

const Settings = () => {
    return (
        <main>
            <p>Welcome To Settings</p>
        </main>
    );
};

export default Settings;
