import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './home-style.css';
import { initClock } from '../clock';
import tapSoundFile from '../sounds/tap.mp3';
import welcomeSoundFile from '../sounds/welcome.wav';
import errorSoundFile from '../sounds/wrong.mp3';
import byeSoundFile from '../sounds/bye.mp3';
import placeholderImage from '../img/placeholder-img.jpg';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const [kids, setKids] = useState([]);
    const [selectedKids, setSelectedKids] = useState([]);
    const [guardians, setGuardians] = useState([]);
    const [checkInError, setCheckInError] = useState('');
    const [confirmationStage, setConfirmationStage] = useState(false);
    const [selectedGuardianName, setSelectedGuardianName] = useState(null);
    const [customGuardianName, setCustomGuardianName] = useState('');
    const [showCustomGuardianInput, setShowCustomGuardianInput] = useState(false);
    const [isConfirmationComplete, setIsConfirmationComplete] = useState(false);
    const [confirmationMessages, setConfirmationMessages] = useState([]);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [kidsCheckedInToday, setKidsCheckedInToday] = useState([]);
    const [selectedCheckoutKids, setSelectedCheckoutKids] = useState([]);

    const tapSound = useRef(new Audio(tapSoundFile));
    const welcomeSound = useRef(new Audio(welcomeSoundFile));
    const errorSound = useRef(new Audio(errorSoundFile));
    const byeSound = useRef(new Audio(byeSoundFile));

    useEffect(() => {
        initClock();

        return () => {
            clearInterval(window.clockInterval);
        };
    }, []);

    const fetchKids = async () => {
        try {
            const response = await fetch('https://attendance.flowerhousecrechecare.com.ng/php/fetch-unchecked-kids.php');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setKids(data);
        } catch (error) {
            console.error('Error fetching kids:', error);
        }
    };

    const fetchGuardians = async (kidIds) => {
        try {
            const response = await axios.get('https://attendance.flowerhousecrechecare.com.ng/php/get-guardians.php', {
                params: {
                    kid_ids: kidIds
                }
            });
            setGuardians(response.data);
        } catch (error) {
            console.error('Error fetching guardians:', error);
        }
    };

    const handleGuardianSelect = (guardian) => {
        playTapSound();
        if (!showCustomGuardianInput) {
            if (selectedGuardianName === guardian.guardian_name) {
                setSelectedGuardianName('');
            } else {
                setSelectedGuardianName(guardian.guardian_name);
            }
        }
    };

    const handleToggleCustomGuardianInput = () => {
        if (!showCustomGuardianInput) {
            setSelectedGuardianName('');
        }
        setShowCustomGuardianInput(prevState => !prevState);
        setCustomGuardianName('');
    };

    const handleCardClick = (kid) => {
        playTapSound();
        const isSelected = selectedKids.some(selected => selected.kid_id === kid.kid_id);

        if (isSelected) {
            setSelectedKids(prevSelectedKids => {
                const updatedKids = prevSelectedKids.filter(selected => selected.kid_id !== kid.kid_id);
                return updatedKids;
            });
        } else {
            setSelectedKids(prevSelectedKids => {
                const updatedKids = [...prevSelectedKids, kid];
                return updatedKids;
            });
        }
    };

    const handleCardClickForCheckout = (kid) => {
        playTapSound();
        const isSelected = selectedCheckoutKids.some(selected => selected.kid_id === kid.kid_id);

        if (isSelected) {
            setSelectedCheckoutKids(prevSelectedCheckoutKids => {
                const updatedKids = prevSelectedCheckoutKids.filter(selected => selected.kid_id !== kid.kid_id);
                return updatedKids;
            });
        } else {
            setSelectedCheckoutKids(prevSelectedCheckoutKids => {
                const updatedKids = [...prevSelectedCheckoutKids, kid];
                return updatedKids;
            });
        }
    };


    const handleContinue = () => {
        if (selectedKids.length === 0) {
            playErrorSound();
            setCheckInError('Please select at least one kid.');
            return;
        }

        const familyIds = new Set(selectedKids.map(kid => kid.family_id));
        if (familyIds.size > 1) {
            playErrorSound();
            setCheckInError('Selected kids do not belong to the same family.');
        } else {
            setCheckInError('');
            playTapSound();
            fetchGuardians(selectedKids.map(kid => kid.kid_id));
            setConfirmationStage(true);
        }
    };

    const getCurrentTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return timestamp;
    };

    const formatTimeForDisplay = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    const handleConfirm = () => {
        if (!selectedGuardianName && !showCustomGuardianInput) {
            playErrorSound();
            setCheckInError('Please select or enter a guardian name.');
            return;
        }

        const guardianToLog = showCustomGuardianInput ? customGuardianName : selectedGuardianName;
        const timestamp = getCurrentTimestamp();
        const displayTime = formatTimeForDisplay(); // Use this function to format time for display

        // Create confirmation messages as objects
        const confirmationMsgs = selectedKids.map(kid => ({
            kidName: `${kid.first_name} ${kid.surname}`,
            guardianName: guardianToLog,
            photo: kid.photo,
            time: displayTime
        }));

        setConfirmationMessages(confirmationMsgs);
        setIsConfirmationComplete(true);

        playWelcomeSound();

        // Send the check-in data to the backend
        selectedKids.forEach(kid => {
            const checkInData = {
                kid_id: kid.kid_id,
                kid_name: `${kid.surname} ${kid.first_name}`,
                checkin_by: guardianToLog,
                time_in: timestamp
            };

            fetch('https://attendance.flowerhousecrechecare.com.ng/php/checkin.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checkInData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                    } else {
                        console.error(`Failed to save check-in data for ${kid.first_name} ${kid.surname}.`);
                    }
                })
                .catch(error => {
                    console.error('Error saving check-in data:', error);
                });
        });

        // Show confirmation message and close the modal after 5 seconds
        setTimeout(() => {
            setIsConfirmationComplete(false);
            handleModalClose();
        }, 5000);
    };



    const handleModalClose = () => {
        playTapSound();
        setShowModal(false);
        setSelectedKids([]);
        setCheckInError('');
        setSelectedGuardianName('');
        setShowCustomGuardianInput(false);
        setConfirmationStage(false);
        setConfirmationMessages([]);
    };

    const goToPreviousStage = () => {
        setConfirmationStage(false);
        setSelectedGuardianName('');
        setShowCustomGuardianInput(false);
        setCustomGuardianName('');
    };

    const playTapSound = () => {
        tapSound.current.currentTime = 0;
        tapSound.current.play();
    };

    const playWelcomeSound = () => {
        welcomeSound.current.currentTime = 0;
        welcomeSound.current.play();
    };

    const playErrorSound = () => {
        errorSound.current.currentTime = 0;
        errorSound.current.play();
    };

    const playByeSound = () => {
        byeSound.current.currentTime = 0;
        byeSound.current.play();
    };

    const fetchKidsCheckedInToday = async () => {
        try {
            const response = await fetch('https://attendance.flowerhousecrechecare.com.ng/php/checkedin-kids.php');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setKidsCheckedInToday(data);
        } catch (error) {
            console.error('Error fetching checked-in kids:', error);
        }
    };



    const handleCheckoutContinue = () => {
        if (selectedCheckoutKids.length === 0) {
            playErrorSound();
            setCheckInError('Please select at least one kid.');
            return;
        }

        playTapSound();
        setCheckInError('');
        fetchGuardians(selectedCheckoutKids.map(kid => kid.kid_id));
        setConfirmationStage(true);
    };

    const handleCheckoutConfirm = () => {
        if (!selectedGuardianName && !showCustomGuardianInput) {
            playErrorSound();
            setCheckInError('Please select or enter a guardian name.');
            return;
        }

        const guardianToLog = showCustomGuardianInput ? customGuardianName : selectedGuardianName;
        const timestamp = getCurrentTimestamp();
        const displayTime = formatTimeForDisplay();

        const confirmationMsgs = selectedCheckoutKids.map(kid => ({
            kidName: `${kid.first_name} ${kid.surname}`,
            guardianName: guardianToLog,
            time: displayTime,
            photo: kid.photo
        }));

        setConfirmationMessages(confirmationMsgs);
        setIsConfirmationComplete(true);
        playByeSound();

        selectedCheckoutKids.forEach(kid => {
            const checkoutData = {
                kid_id: kid.kid_id,
                checkout_by: guardianToLog,
                time_out: timestamp
            };

            fetch('https://attendance.flowerhousecrechecare.com.ng/php/checkout.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checkoutData),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                    } else {
                        console.error(`Failed to save checkout data for ${kid.first_name} ${kid.surname}.`);
                    }
                })
                .catch(error => {
                    console.error('Error saving checkout data:', error);
                });
        });

        setTimeout(() => {
            setIsConfirmationComplete(false);
            handleCheckoutModalClose();
        }, 5000);
    };

    const resetCheckoutModalState = () => {
        setSelectedCheckoutKids([]);
        setSelectedGuardianName('');
        setCustomGuardianName('');
        setShowCustomGuardianInput(false);
        setConfirmationStage(false);
        setIsConfirmationComplete(false);
        setConfirmationMessages([]);
    };

    const handleCheckoutModalClose = () => {
        playTapSound();
        setShowCheckoutModal(false);
        resetCheckoutModalState();
        setCheckInError('');
    };

    // Function to capitalize the first letter of each word
    const capitalizeWords = (str) => {
        if (!str) return 'N/A';
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };


    return (
        <main>
            <div className="datetime">
                <div className="date">
                    <span id="dayname">Day</span>,{' '}
                    <span id="month">Month</span>{' '}
                    <span id="daynum"> 00</span>,{' '}
                    <span id="year">Year</span>
                </div>
                <div className="time">
                    <span id="hour">00</span>: <span id="minutes">00</span>:
                    <span id="seconds">00</span>
                    <span id="period">AM</span>
                </div>
            </div>

            <div className="buttons">
                <div className="checkin-btn aqua" onClick={() => { setShowModal(true); playTapSound(); fetchKids(); }}>Check In</div>
                <div
                    className="checkout-btn aqua aqua2"
                    onClick={() => {
                        fetchKidsCheckedInToday();
                        setShowCheckoutModal(true);
                        playTapSound();
                    }}
                >
                    Check Out
                </div>

            </div>

            {/* Check-in Modal */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <i className="fas fa-times-circle close" onClick={handleModalClose}></i>

                        {!confirmationStage && !isConfirmationComplete && (
                            <>
                                <div className="modal-top">
                                    <h2 className="select-kids">Select Kid(s)</h2>
                                    <button className="modal-continue" onClick={handleContinue}>Continue</button>
                                </div>
                                {checkInError && <p className="error-message">{checkInError}</p>}
                                <div className="kids-container">
                                    {kids.sort((a, b) => a.surname.localeCompare(b.surname)).map(kid => (
                                        <div
                                            key={kid.kid_id}
                                            className={`kid-card ${selectedKids.some(selected => selected.kid_id === kid.kid_id) ? 'selected' : ''}`}
                                            onClick={() => handleCardClick(kid)}
                                        >
                                            {kid.photo ? (
                                                <img
                                                    src={`https://attendance.flowerhousecrechecare.com.ng/uploads/${kid.photo}`}
                                                    alt="Kid"
                                                    className="display-photo"
                                                />
                                            ) : (
                                                <img
                                                    src={placeholderImage}
                                                    alt="Placeholder-img"
                                                    className="display-photo"
                                                />
                                            )}
                                            <div className="kid-details">
                                                <h3 className="kid-name">{`${kid.surname} ${kid.first_name}`}</h3>
                                                <p className="kid-gender">{kid.gender}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {confirmationStage && !isConfirmationComplete && (
                            <>
                                <div className="modal-top">
                                    <button className="back-btn modal-continue" onClick={goToPreviousStage}>Back</button>
                                    <h2 className="select-guardian">Check-in By?</h2>
                                    <button className="modal-continue" onClick={handleConfirm}>Confirm</button>
                                </div>
                                {checkInError && <p className="error-message">{checkInError}</p>}
                                <div className="kids-container">
                                    {guardians.map(guardian => (
                                        <div
                                            key={guardian.guardian_id}
                                            className={`kid-card guardian-card ${selectedGuardianName === guardian.guardian_name ? 'selected' : ''}`}
                                            onClick={() => handleGuardianSelect(guardian)}
                                            style={{ pointerEvents: showCustomGuardianInput ? 'none' : 'auto' }}
                                        >
                                            <div className="guardian-details">
                                                <h3 className="guardian-name">{guardian.guardian_name}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {showCustomGuardianInput && (
                                    <input
                                        type="text"
                                        placeholder="Enter other name"
                                        value={customGuardianName}
                                        onChange={(e) => setCustomGuardianName(e.target.value)}
                                        className="custom-guardian-input"
                                    />
                                )}
                                <button
                                    className="enter-other-name-btn"
                                    onClick={handleToggleCustomGuardianInput}
                                >
                                    {showCustomGuardianInput ? 'Cancel' : 'Enter Other Name'}
                                </button>
                            </>
                        )}

                        {isConfirmationComplete && (
                            <div className="confirmation-page">
                                <div className="confirmation-header">
                                    <span className="checkmark">&#10004;</span>
                                    <h2 className='confirm-msg'>Check-in Successful</h2>
                                </div>
                                <div className='confirmation-body'>
                                    {confirmationMessages.map((msg, index) => (
                                        <div key={index} className="confirmation-message">
                                            {msg.photo ? (
                                                <img
                                                    src={`https://attendance.flowerhousecrechecare.com.ng/uploads/${msg.photo}`}
                                                    alt="Kid"
                                                    className="confirm-photo"
                                                />
                                            ) : (
                                                <img
                                                    src={placeholderImage}
                                                    alt="Placeholder-img"
                                                    className="confirm-photo"
                                                />
                                            )}
                                            <p className="message">
                                                <span className="confirm-details">{msg.kidName}</span> checked in by <span className="confirm-details confirmguardian-name">{msg.guardianName}</span> at <span className="confirm-details">{msg.time}</span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Checkout Modal */}
            {showCheckoutModal && (
                <div className="modal">
                    <div className="modal-content">
                        <i className="fas fa-times-circle close" onClick={handleCheckoutModalClose}></i>

                        {!confirmationStage && !isConfirmationComplete && (
                            <>
                                <div className="modal-top">
                                    <h2 className="select-kids">Select Kid(s)</h2>
                                    <button className="modal-continue" onClick={handleCheckoutContinue}>Continue</button>
                                </div>
                                {checkInError && <p className="error-message">{checkInError}</p>}
                                <div className="kids-container">
                                    {kidsCheckedInToday.length > 0 ? (
                                        kidsCheckedInToday
                                            .sort((a, b) => a.surname.localeCompare(b.surname))
                                            .map(kid => (
                                                <div
                                                    key={kid.kid_id}
                                                    className={`kid-card ${selectedCheckoutKids.some(selected => selected.kid_id === kid.kid_id) ? 'selected' : ''}`}
                                                    onClick={() => handleCardClickForCheckout(kid)}
                                                >
                                                    {kid.photo ? (
                                                        <img
                                                            src={`https://attendance.flowerhousecrechecare.com.ng/uploads/${kid.photo}`}
                                                            alt="Kid"
                                                            className="display-photo"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={placeholderImage}
                                                            alt="Placeholder-img"
                                                            className="display-photo"
                                                        />
                                                    )}
                                                    <div className="kid-details">
                                                        <h3 className="kid-name">{`${kid.surname} ${kid.first_name}`}</h3>
                                                        <p className="kid-gender">{kid.gender}</p>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <p className='nokids-error'>No kids have been checked in.</p>
                                    )}
                                </div>

                            </>
                        )}

                        {confirmationStage && !isConfirmationComplete && (
                            <>
                                <div className="modal-top">
                                    <button className="back-btn modal-continue" onClick={goToPreviousStage}>Back</button>
                                    <h2 className="select-guardian">Check-out By?</h2>
                                    <button className="modal-continue" onClick={handleCheckoutConfirm}>Confirm</button>
                                </div>
                                {checkInError && <p className="error-message">{checkInError}</p>}
                                <div className="kids-container">
                                    {guardians.map(guardian => (
                                        <div
                                            key={guardian.guardian_id}
                                            className={`kid-card guardian-card ${selectedGuardianName === guardian.guardian_name ? 'selected' : ''}`}
                                            onClick={() => handleGuardianSelect(guardian)}
                                            style={{ pointerEvents: showCustomGuardianInput ? 'none' : 'auto' }}
                                        >
                                            <div className="guardian-details">
                                                <h3 className="guardian-name">{guardian.guardian_name}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {showCustomGuardianInput && (
                                    <input
                                        type="text"
                                        placeholder="Enter other name"
                                        value={customGuardianName}
                                        onChange={(e) => setCustomGuardianName(e.target.value)}
                                        className="custom-guardian-input"
                                    />
                                )}
                                <button
                                    className="enter-other-name-btn"
                                    onClick={handleToggleCustomGuardianInput}
                                >
                                    {showCustomGuardianInput ? 'Cancel' : 'Enter Other Name'}
                                </button>
                            </>
                        )}

                        {isConfirmationComplete && (
                            <div className="confirmation-page">
                                <div className="confirmation-header">
                                    <span className="checkmark">&#10004;</span>
                                    <h2 className='confirm-msg'>Checkout Successful</h2>
                                </div>
                                <div className='confirmation-body'>
                                    {confirmationMessages.map((msg, index) => (
                                        <div key={index} className="confirmation-message">
                                            {msg.photo ? (
                                                <img
                                                    src={`https://attendance.flowerhousecrechecare.com.ng/uploads/${msg.photo}`}
                                                    alt="Kid"
                                                    className="confirm-photo"
                                                />
                                            ) : (
                                                <img
                                                    src={placeholderImage}
                                                    alt="Placeholder-img"
                                                    className="confirm-photo"
                                                />
                                            )}
                                            <p className="message">
                                                <span className="confirm-details">{msg.kidName}</span> checked out by <span className="confirm-details confirmguardian-name">{msg.guardianName}</span> at <span className="confirm-details">{msg.time}</span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}




        </main>
    );
};

export default Home;
