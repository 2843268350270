import React, { useState, useEffect } from 'react';
import Modal from './Modal'; 

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        totalKids: 0,
        totalKidsPresent: 0,
        totalKidsAbsent: 0,
        totalCheckedOut: 0,
        totalNotCheckedOut: 0,
    });

    const [modalData, setModalData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await fetch('https://attendance.flowerhousecrechecare.com.ng/php/dashboard-data.php');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setDashboardData(data);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []);

    const handleViewClick = async (category) => {
        setModalTitle(category);
        try {
            const response = await fetch(`https://attendance.flowerhousecrechecare.com.ng/php/kids-category.php?category=${category}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setModalData(data);
            setIsModalOpen(true);
        } catch (error) {
            console.error(`Error fetching data for ${category}:`, error);
        }
    };

    return (
        <main className='dashboard'>
            <div className='dashboard-container'>

                <div className='dashboard-item'>
                    <p className='dashboard-title title-1'>Total Registered Kids</p>
                    <h2 className='dashboard-data' id='totalKids'>{dashboardData.totalKids}</h2>
                    <button className='viewBtn' id='totalKidsBtn' onClick={() => handleViewClick('Total Registered Kids')}>VIEW</button>
                </div>
                <div className='dashboard-item'>
                    <p className='dashboard-title title-2'>Kids Present</p>
                    <h2 className='dashboard-data' id='totalKidsPresent'>{dashboardData.totalKidsPresent}</h2>
                    <button className='viewBtn' id='totalKidsPresentBtn' onClick={() => handleViewClick('Kids Present')}>VIEW</button>
                </div>
                <div className='dashboard-item'>
                    <p className='dashboard-title title-3'>Kids Absent</p>
                    <h2 className='dashboard-data' id='totalKidsAbsent'>{dashboardData.totalKidsAbsent}</h2>
                    <button className='viewBtn' id='totalKidsAbsentBtn' onClick={() => handleViewClick('Kids Absent')}>VIEW</button>
                </div>


                <div className='dashboard-item'>
                    <p className='dashboard-title title-4'>Checked Out</p>
                    <h2 className='dashboard-data' id='totalCheckedOut'>{dashboardData.totalCheckedOut}</h2>
                    <button className='viewBtn' id='totalCheckedOutBtn' onClick={() => handleViewClick('Checked Out')}>VIEW</button>
                </div>
                <div className='dashboard-item'>
                    <p className='dashboard-title title-5'>Not Checked Out</p>
                    <h2 className='dashboard-data' id='totalNotCheckedOut'>{dashboardData.totalNotCheckedOut}</h2>
                    <button className='viewBtn' id='totalNotCheckedOutBtn' onClick={() => handleViewClick('Not Checked Out')}>VIEW</button>
                </div>

            </div>

{/* Modal Component */}
{isModalOpen && (
    <Modal
        title={modalTitle}
        onClose={() => setIsModalOpen(false)}
        showCancel={false}
        showSubmitButton={false}
    >
        {modalData.length > 0 ? (
            <div className="table-container"> {/* Added a container to center the table */}
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            {(modalTitle === 'Kids Present' || modalTitle === 'Checked Out' || modalTitle === 'Not Checked Out') && <th>Drop Off</th>}
                            {(modalTitle === 'Kids Present' || modalTitle === 'Checked Out' || modalTitle === 'Not Checked Out') && <th>Time In</th>}
                            {modalTitle === 'Checked Out' && <th>Pick Up</th>}
                            {modalTitle === 'Checked Out' && <th>Time Out</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {modalData.map((kid, index) => {
                            // Parse times
                            const checkInDate = kid.checkInTime ? new Date(kid.checkInTime) : null;
                            const checkOutDate = kid.checkOutTime ? new Date(kid.checkOutTime) : null;

                            // Function to format time
                            const formatTime = (date) => {
                                if (!date) return '';
                                const hours = date.getHours() % 12 || 12;
                                const minutes = date.getMinutes().toString().padStart(2, '0');
                                const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
                                return `${hours}:${minutes} ${ampm}`;
                            };

                            // Function to capitalize the first letter of each word
                            const capitalizeWords = (str) => {
                                if (!str) return 'N/A';
                                return str.replace(/\b\w/g, char => char.toUpperCase());
                            };

                            return (
                                <tr key={kid.kid_id}>
                                    <td>{index + 1}</td>
                                    <td>{kid.name}</td>
                                    {(modalTitle === 'Kids Present' || modalTitle === 'Checked Out' || modalTitle === 'Not Checked Out') && <td>{capitalizeWords(kid.checkin_by)}</td>}
                                    {(modalTitle === 'Kids Present' || modalTitle === 'Checked Out' || modalTitle === 'Not Checked Out') && <td>{kid.checkInTime ? formatTime(checkInDate) : 'N/A'}</td>}
                                    {modalTitle === 'Checked Out' && <td>{capitalizeWords(kid.checkout_by)}</td>}
                                    {modalTitle === 'Checked Out' && <td>{kid.checkOutTime ? formatTime(checkOutDate) : 'N/A'}</td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        ) : (
            <p className='nokids-error'>No data available.</p>
        )}
    </Modal>
)}











        </main>
    );
};

export default Dashboard;
